import React, { useEffect, useState } from "react"
import https from "../services/https"
import { API_URL } from "../primeagile.config"
import PaymentLinkCheckout from "../components/Builder/Components/Stripe/PaymentLinkCheckout"
import ElementComponent from "../components/Builder/Components/Stripe/ElementComponent"
// import { Head } from "../components/Builder/Head"
import styled from "styled-components"
import { useRef } from "../hooks/stateHooks"
import { Notify } from "../components/Builder/Components/Notification/Notify"
import { navigate } from "gatsby"

const Head = () => {
  return (
    <>
<style>
`
background-color: #dc3545;
`
  </style>
    </>
  )
}

const ContainerDiv = styled.div`
  height: 87vh;
  font-family: "Inter", sans-serif;
  padding-bottom: 1rem;

  h1 {
    font-size: 1.3em;
    color: black;
    font-weight: 300;
  }

  .step1 {
    display: ${props => (props.step1 ? "flex" : "none")};
  }

  .step2 {
    display: ${props => (props.step2 ? "flex" : "none")};
  }

  .content {
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    margin: 2rem;

    .payment {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 50%;

      .bold {
        font-weight: 600;
      }

      form {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        padding: 25px;
        background: rgb(236, 238, 244);
      }
    }

    .contact {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 50%;

      .input-container {
        padding: 15px 15px 40px 15px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        background: rgb(236, 238, 244);
      }

      .bold {
        font-weight: 600;
      }

      .inputs {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 25px;

        label {
          font-size: 1.1em;
          font-weight: 500;
          color: #333;
          display: block;
          margin-bottom: 5px;
        }

        input {
          width: 100%;
          padding: 12px 15px;
          border: 1px solid #ddd;
          border-radius: 6px;
          font-size: 1em;
          background: #fff;
          transition: all 0.3s ease;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

          &:focus {
            border-color: #e21219;
            box-shadow: 0 0 5px rgba(226, 18, 25, 0.2);
            outline: none;
          }

          &:disabled {
            color: #999;
            background: #f8f8f8;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  button {
    font-size: clamp(1rem, 2.5vh, 1.5rem);
    font-weight: 500;
    background-color: #e21219;
    color: white;
    padding: 15px 30px;
    outline: none;
    border-radius: 4px;
    border: none;
    margin-top: 10px;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
      background-color: #c00f14;
    }
  }

  .svg-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 40%;
  }

  .svg-container img {
    max-width: 100%;
    height: auto;
  }

  @media screen and (max-width: 40rem) {
    .content {
      padding: 1rem 1rem 2rem 1rem;
      margin: 2rem 0;
    }

    .content .payment,
    .content .contact {
      max-width: 100%;
    }

    .content .contact {
      transform: translateY(4rem);
    }

    .svg-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.2; /* Lower opacity for background effect */
      z-index: -10;
      transform: translateY(-4rem);
    }

    .svg-container img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: 12px 4rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    height: 40px;
  }
`

const ProfileIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: rgb(233, 146, 146);
  border-radius: 50%;
  cursor: pointer;

  svg {
    color: white;
  }
`

const Pay = ({ location: { search } }) => {
  const paymentLinkID = search?.split("=")[1]
  const [paymentRecord, setPaymentRecord] = useState(null)
  const [secret, setSecret] = useState("")
  const [loading, setLoading] = useState(false)
  const [loadingLink, setLoadingLink] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [steps, setSteps] = useState({ step1: true, step2: false })
  const [formdata, setFormdata] = useState({
    email: "",
    lastName: "",
    phone: "",
    firstName: "",
    entityName: "",
  })

  const updateRef = useRef(null)

  const url = `${API_URL}/open/payment-link?tenant=${process.env.GATSBY_BUILDSITE}&uuid=${paymentLinkID}`

  useEffect(() => {
    if (!paymentLinkID) return

    setLoadingLink(true)
    https(
      url,
      "get",
      undefined,
      undefined,
      res => {
        setPaymentRecord(res.data)
        setLoadingLink(false)
      },
      err => {
        setError(err.message)
        setLoadingLink(false)
      }
    )
  }, [paymentLinkID])

  useEffect(() => {
    if (!paymentRecord?.amount) return

    setLoading(true)
    const price = paymentRecord.amount
      ?.toLocaleString("en", { minimumFractionDigits: 2 })
      .replace(/[^a-zA-Z0-9]/g, "")

    const data = {
      amount: price,
      applicationFeeAmount: 0,
      automaticPaymentMethods: {
        enabled: Number(paymentRecord.amount) >= 500 ? false : true,
        allow_redirects: "NEVER",
      },
      currency: paymentRecord.currency || "usd",
      paymentMethodTypes: ["us_bank_account"],
      tenant: process.env.GATSBY_BUILDSITE,
    }

    https(
      `${API_URL}/open/commerce/stripe/payment_intent`,
      "post",
      data,
      undefined,
      res => {
        setSecret(res.data.clientSecret)
        setLoading(false)
      },
      err => {
        setError(err.message)
        setLoading(false)
      }
    )
  }, [paymentRecord])

  function handleSuccess(item) {
    const data = {
      entityId: paymentRecord.entity?.entityId,
      paymentW: {
        confirmationCode: item.paymentIntent.id,
        amount: item.paymentIntent.amount,
        gatewayId: Number(1051),
        status: item.paymentIntent.status,
        channel: item.paymentIntent.payment_method,
        jsonData: {},
      },
      orderItemWList: [],
      jsonData: {
        merchantType: "Stripe",
        email: formdata.email,
        gatewayId: Number(1051),
        paymentDate: new Date(),
      },
      orderNotificationEmails: [formdata.email],
      status: "NEW",
    }

    const link = `${API_URL}/open/commerce/order?client=${process.env.GATSBY_BUILDSITE}`

    setIsLoading(true)

    https(
      link,
      "post",
      data,
      undefined,
      res => {
        setIsLoading(false)
        Notify({ type: "success", text: "Payment successful" })
        navigate("/")
      },
      error => {
        setIsLoading(false)
      }
    )
  }

  function handleChange(e) {
    const { value, name } = e.target
    setFormdata(p => ({
      ...p,
      [name]: value,
    }))
  }

  const handleSubmit = () => {
    const { firstName, lastName, email, phone, entityName } = formdata

    if (!email || !phone) {
      Notify({ type: "error", text: "Please fill all personal information" })
      return
    }

    updateRef.current.submit()
  }

  const handleNextStep = () => {
    setSteps(s => ({ ...s, step1: false, step2: true }))
  }

  if (error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error}</p>
      </div>
    )
  }

  if (!paymentLinkID) {
    return (
      <div>
        <h1>Payment link not provided</h1>
      </div>
    )
  }

  if (loadingLink) {
    return (
      <Container>
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div class="spinner text-primary" role="status"></div>
          <h2>Loading payment link...</h2>
        </div>
      </Container>
    )
  }

  if (!paymentRecord) {
    return (
      <Container>
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>
            Unable to fetch payment record. Please check with your provider.
          </h2>
        </div>
      </Container>
    )
  }

  if (loading) {
    return (
      <Container>
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div class="spinner text-primary" role="status"></div>
          <h2>Creating payment intent...</h2>
        </div>
      </Container>
    )
  }

  return (
    <>
      <Container step1={steps.step1} step2={steps.step2}>
        {secret ? (
          <ElementComponent secret={secret}>
            <div className="content step1">
              <div className="payment">
                <h1>
                  Payment of{" "}
                  <span className="bold">
                    {paymentRecord.currency === "usd"
                      ? "$"
                      : paymentRecord.currency}
                    {paymentRecord.amount
                      ? Number(paymentRecord.amount).toLocaleString()
                      : 0}
                  </span>{" "}
                  from{" "}
                  <span className="bold">
                    {paymentRecord.entity?.entityName}
                  </span>
                </h1>

                <PaymentLinkCheckout
                  handleSuccess={handleSuccess}
                  updateRef={updateRef}
                  setIsLoading={setIsLoading}
                  setSteps={setSteps}
                />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "2em",
                  }}
                >
                  <button disabled={isLoading} onClick={handleNextStep}>
                    {isLoading ? "Processing..." : "Next Step"}
                  </button>
                </div>
              </div>

              <div className="svg-container">
                <img
                  src={
                    require("../images/ibcscorp/svg/payment-illustration-1.svg")
                      .default
                  }
                  alt="Payment"
                />
              </div>
            </div>

            <div className="content step2">
              <ContactSection
                formdata={formdata}
                handleChange={handleChange}
                isLoading={isLoading}
                handleSubmit={handleSubmit}
              />

              <div className="svg-container">
                <img
                  src={
                    require("../images/ibcscorp/svg/payment-illustration-2.svg")
                      .default
                  }
                  alt="Payment"
                />
              </div>
            </div>
          </ElementComponent>
        ) : (
          "Unable to process payment."
        )}
      </Container>
    </>
  )
}

export default Pay

const NavBar = () => {
  return (
    <HeaderContainer>
      <Logo onClick={() => navigate("/")}>
        <img
          src="https://images.primeagile.com/ibcscorp_com/image/32401/logo-1200.svg"
          alt="Logo"
        />
      </Logo>
      <ProfileIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M19.652 19.405c.552-.115.882-.693.607-1.187c-.606-1.087-1.56-2.043-2.78-2.771C15.907 14.509 13.98 14 12 14s-3.907.508-5.479 1.447c-1.22.728-2.174 1.684-2.78 2.771c-.275.494.055 1.072.607 1.187a37.5 37.5 0 0 0 15.303 0"
          />
          <circle cx="12" cy="8" r="5" fill="currentColor" />
        </svg>
      </ProfileIcon>
    </HeaderContainer>
  )
}

const ContactSection = ({
  formdata,
  handleChange,
  isLoading,
  handleSubmit,
}) => {
  return (
    <div className="contact">
      <h1>
        <span className="bold">Personal</span> information
      </h1>
      <div className="input-container">
        <div className="inputs">
          <div>
            <label>Entity Name</label>
            <input
              placeholder="Entity Name"
              className="form-control"
              onChange={e => handleChange(e)}
              name="entityName"
              required
              value={formdata.entityName}
            />
          </div>
          <div>
            <label>Email</label>
            <input
              placeholder="Email"
              className="form-control"
              onChange={e => handleChange(e)}
              name="email"
              required
              value={formdata.email}
            />
          </div>
          <div>
            <label>Phone Number</label>
            <input
              placeholder="Phone Number"
              className="form-control"
              onChange={e => handleChange(e)}
              name="phone"
              value={formdata.phone}
              required
            />
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: "2em",
        }}
      >
        <button disabled={isLoading} onClick={handleSubmit}>
          {isLoading ? "Processing..." : "Pay Now"}
        </button>
      </div>
    </div>
  )
}

const Container = ({ step1, step2, children }) => {
  return (
    <ContainerDiv step1={step1} step2={step2}>
      <Head />
      <NavBar />
      {children}
    </ContainerDiv>
  )
}
